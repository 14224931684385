<template>
  <div class='loginBox'>
    <div class="mainBox">
      <div class="logoBox"></div>
      <div class="centerBox">
        <div class="title">登录/注册</div>
        <!-- <myStep 
        :stepArr = 'stepArr'
        :active='active'></myStep> -->
        <el-form 
          :model="registerRuleForm" 
          :rules="rules" 
          ref="ruleForm" 
          >
          <div class='registTel' v-show='active===0'>
            <el-form-item prop="tel">
              <div class="tip">* 该手机号将作为登录账号</div>
              <el-input class='resetInput' 
                placeholder="请输入手机号" 
                v-model.trim="registerRuleForm.tel"
                clearable>
              </el-input>
            </el-form-item>
            <el-form-item class='codeBox' prop='code'>
              <getCode
                :code='registerRuleForm.code' 
                :changeCode='changeCode'
                @sendCode='sendCode'
                :isBegin='isBegin'>
              </getCode>
            </el-form-item>
          </div>
          <div class="registTel" v-show='active===1'>
            <el-form-item prop="pwd">
              <div class="tip">* 请设置登录密码</div>
              <el-input class='resetInput' 
              placeholder="请输入新密码" 
              show-password
              v-model.trim="registerRuleForm.pwd"
              clearable>
              </el-input>
            </el-form-item>
            <el-form-item prop="rePwd" class='codeBox'>
              <el-input class='resetInput' 
              placeholder="请再次输入新密码" 
              show-password
              v-model.trim="registerRuleForm.rePwd"
              clearable>
              </el-input>
            </el-form-item>
          </div>
          <div class="loginItem" v-show='active===2'>
            <div class="codeImg">
              <img :src="codeSrc" v-if='codeSrc'>
            </div>
            <div class='codeLoginText'>请用手机打开微信，扫描二维码</div>
          </div>
          <div class="resetPwdSuccess" v-show='active===3'>注册成功啦!</div>
          <el-form-item class='bottomBtnGrow'>
            <!-- <el-button icon="el-icon-arrow-left" type='primary' plain class='backBtn' 
            v-show='active===1' @click='stepPre'>返回</el-button> -->
            <el-button :style='active===1?"":"width:340px"' :loading="btnTextLoading" class='mainBtn' 
          v-show='btnText' @click='stepNext'>{{btnText}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
// import myStep from '@/components/step.vue';
import getCode from '@/components/getCodeByTel.vue';
import {isTel} from '@/utils/index.js';
import {register,sendVerifyCode,getTmpSubscribeQrCode,getTokenByTicket,getUserInfoAfterScan,userBindOpenId} from '@/api/index.js';
import { ElMessage } from 'element-plus'
export default defineComponent({
  components:{
    // myStep,
    getCode
  },
  data(){
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerRuleForm.pwd) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };
    return {
      codeSrc:'',
      btnTextLoading:false,
      rules:{
        tel:[
          { required: true, message: '请输入手机号', trigger: ['blur','change']},
          { pattern:isTel, message: '手机号格式错误', trigger: ['blur','change']}
        ],
        code:[
          {required: true, message: '验证码不能为空', trigger: ['blur','change']}
        ],
        pwd:[
          { validator: validatePass, trigger: ['blur','change'] }
        ],
        rePwd:[
          { validator: validatePass2, trigger: ['blur','change'] }
        ]
      },
      registerRuleForm:{
        tel:'',
        code:'',
        pwd:'',
        rePwd:''
      },
      isBegin:false,
      active:0,
      stepArr:['确定手机号','设置密码'],
      btnText:'下一步',

    }
  },
  methods:{
    changeCode(val){
      this.registerRuleForm.code = val
    },
    async sendCode(){
      if(this.registerRuleForm.tel){
        let res = await sendVerifyCode({
          tel:this.registerRuleForm.tel
        });
      }else{
        alert('请输入手机号')
      }
    },
    stepNext(){
      console.log(this.active);
      if(this.active === 3){
        this.$router.push('/login')
      }else{
        if(this.active === 0){
          // 注册手机号
          console.log('注册手机号')
          this.btnText = '下一步';
          this.$refs['ruleForm'].validateField('tel',(tellErr) => {
            if(tellErr){
              console.log('tellErr:',tellErr)
            }else{
              this.$refs['ruleForm'].validateField('code',async (codeErr) => {
                if(codeErr){
                  console.log('codeErr:',codeErr)
                }else{
                  // this.active += 1
                  let params = {
                    code: this.registerRuleForm.code,
                    tel: this.registerRuleForm.tel,
                    openId: sessionStorage.getItem("openId")
                  }
                  let res = await getUserInfoAfterScan(params)
                  console.log('wfewfewf',res);
                  if(res.code == 200){
                    if(res.data.status == 3){
                      sessionStorage.setItem('token',res.data.token.value)
                      this.$router.push('/home/homeMain')
                    }else if(res.data.status == 1){
                      // this.$router.push('/register')
                      this.active  = 1
                    }
                  }
                } 
              })
            }
          })
        }else if(this.active === 1){
          // 输入密码
          console.log('输入密码')
          this.$refs['ruleForm'].validateField('pwd',(pwderr) => {
            if(pwderr){
              console.log('pwderr:',pwderr)
            }else{
              this.$refs['ruleForm'].validateField('rePwd',async (rePwderr) => {
                if(rePwderr){
                  console.log('rePwderr:',rePwderr)
                }else{
                  this.btnTextLoading = true;
                  try {
                    let res = await register({
                      code:this.registerRuleForm.code,
                      password:this.registerRuleForm.pwd,
                      tel:this.registerRuleForm.tel
                    });
                    if(res&&res.code==200&&res.data){
                      let params = {
                        openId: sessionStorage.getItem("openId"),
                        userId: res.data.userId
                      }
                      let res2 = await userBindOpenId(params)
                      console.log(res2);
                      if(res2.code == 200){
                        sessionStorage.setItem('token',res2.data.token.value)
                        this.$router.push("/home/homeMain")
                      }
                      // let ticket = await getTmpSubscribeQrCode({
                      //   sceneStr:'registerBind_'+res.data.userId
                      // });
                      // if(ticket.code==200&&ticket.data&&ticket){
                      //   let ticketData = ticket.data.ticket;
                      //   this.codeSrc = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticketData}`;
                      //   this.btnText = '';
                      //   this.active += 1;
                      //   let scanInfo = '';
                      //   const scanInfoFn = ()=>{
                      //     if(scanInfo){
                      //       return
                      //     };
                      //     setTimeout(async ()=>{
                      //       let scanInfoRes = await getTokenByTicket({
                      //         ticket:ticketData
                      //       });
                      //       if(scanInfoRes.data&&scanInfoRes.data.success==true&&scanInfoRes.data.scanInfo){
                      //         scanInfo = scanInfoRes.data.scanInfo;
                      //         if(scanInfo.success == true){
                      //           this.btnText = '去登录';
                      //           this.active += 1
                      //         }else{
                      //           ElMessage(scanInfo.msg);
                      //         }
                      //         console.log('scanInfoRes:',scanInfoRes)
                      //         console.log('scanInfo:',scanInfo)
                      //       }else{
                      //         scanInfoFn()
                      //       }
                      //     },3000)
                      //   }
                      //   scanInfoFn();
                      // }              
                    }
                  } catch (error) {
                    
                  }finally{
                    this.btnTextLoading = false
                  }
                }
              })
            }
          })
        }
      };
    },
    stepPre(){
      this.btnText = '下一步';
      this.active = 0;
    }
  },
})
</script>

<style scoped lang='scss'>
@import '@/assets/style/comman.scss';
.loginBox{
  width:100%;
  height:100%;
  background: #FFC696;
  position: relative;
  .mainBox{
    @include container;
    height:100%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    .centerBox{
      width:500px;
      height:530px;
      box-sizing: border-box;
      margin:auto;
      padding:30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border-radius:10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      .title{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
        width:100%;
        color:$main-color;
        margin-bottom:60px
      }
      .registTel{
        margin-top:40px;
        .tip{
          font-size: 12px;
          color:#666;
          padding-left:8px;
        }
      }
      .mainBtn{
        @include mainBtn;
        border-radius:20px;
        width:240px;
        margin:0
      }
      .backBtn{
        margin:0;
        border-radius:20px;
        margin-right: 20px;
      }
      .bottomBtnGrow{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .resetInput{
        width:340px;
        margin:0 auto;
        ::v-deep{
          .el-input__inner{
            border-radius:20px;
          }
        }
      }
      .codeBox{
        width:340px;
        margin-top:30px
      }
      .pwdBox{
        ::v-deep{
          .resetInput{
            margin-bottom:0
          }
          .resetInput2{
            margin:10px auto 0 auto
          }
        }
      }
      .resetPwdSuccess{
        width:200px;
        height:200px;
        color:$main-color;
        letter-spacing: 1px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .codeImg{
        width: 230px;
        height: 230px;
        margin:30px auto 20px auto;
        img{
          width:100%;
          display:block;
        }
      }
      .codeLoginText{
        font-size: 12px;
        color:#555;
        text-align: center;
        letter-spacing: 1px;
      }
      
    }
  }
}

</style>